import React from "react"
import PropTypes from "prop-types"
import knockOut from '../images/knock-out.png'
import { Link } from "gatsby"

export default class Breadcrumb extends React.Component {
  render() {
    return (
      <div className="row breadcrumb-row">
        <div className="breadcrumb-background"><img src={knockOut} width="28" height="56" alt="space" /></div>
          <div className="container">
            <div className="row">
              <div className="col-12 tagline">Give Forgotten People a Chance at Jesus</div>
            </div>
            <div className="row">
              <div className="col-6 col-sm-8">
                <h1>{this.props && this.props.title ? this.props.title : 'Hope for Native America'}</h1>
              </div>
              <div className="col-6 col-sm-4 breadcrumb-content">
                <div>
                  <Link to="/">Home</Link>&nbsp;/&nbsp;
                  {this.props && this.props.sectionPath && <Link to={this.props.sectionPath}>{this.props.sectionName ? this.props.sectionName : this.props.title}</Link> }
                  {this.props && !this.props.sectionPath && this.props.title}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
}

Breadcrumb.propTypes = {
  title: PropTypes.string,
  sectionName: PropTypes.string,
  sectionPath: PropTypes.string
}
import React from "react"
import { Link } from "gatsby"

export default function Footer() {
  return (
    <footer className="bg-dark py-5 text-light">
      <div className="container">

        <div className="row">
          <div className="col-xs-8 col-sm-5 pb-5">
            <h4>Contact Us</h4>
            <hr />
            <div className="py-2">
              Have ideas or questions about Hope for Native America? We would love to hear from you by email or phone!
            </div>
            <div className="py-2"><i className="fas fa-envelope mr-2 text-light"></i> <a className="text-light" href="mailto:info@h4na.org">info@h4na.org</a></div>
            <div className="py-2"><i className="fas fa-mobile-alt mr-2 text-light" style={{padding: '4px 6px 4px 6px'}}></i> <a className="text-light" href="tel:8707412181">(870) 741-2181</a></div>
          </div>

          <div className="col-xs-8 col-sm-6 offset-sm-1">
            <h4>Links</h4>
            <hr />
            <div className="pb-1">
              <a className="text-light" href="https://donate.h4na.org/giving">Donate</a>
            </div>
            <div className="pb-1">
              <Link className="text-light" to="/privacy-policy">Privacy Policy</Link>
            </div>
            <div className="pb-1">
              <Link className="text-light" to="/solicitation-disclosure">Solicitation Disclosure</Link>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-sm-8 pb-2 small">
            Hope for Native America is a division of Ron Hutchcraft Ministries, Inc.
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-sm-8 pb-2 small">
            Ron Hutchcraft Ministries, Inc. is a 501(c)(3) tax-exempt non-profit organization. All contributions designated for specific projects shall be applied to those projects. Should we receive more contributions for a given project than can be wisely applied to that project, we will use these funds to meet a similar pressing need.
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-sm-8 small">
            Copyright &copy; 2020 - All Rights Reserved - Ron Hutchcraft Ministries, Inc.
          </div>
        </div>
      </div>
    </footer>
  )
}

import React from "react"
import PropTypes from "prop-types"
import { Navbar, Nav } from "react-bootstrap"
import logo_white from '../images/h4na-logo-white.png'
import logo_black from '../images/h4na-logo.png'
import { Link } from "gatsby"

export default class TopNav extends React.Component {

  render() {
    let navbarClasses = `navbar navbar-expand-lg ${(this.props.useTransparentNav ? "navbar-light" : "navbar-light always-scrolled")} fixed-top py-3`;
    return (
      <>
        <Navbar className={navbarClasses} id="mainNav" expand="lg"
                collapseOnSelect={true}>
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img src={logo_white} height="56" width="56" className="show-no-scroll align-top" alt="Home"></img>
              <img src={logo_black} height="56" width="56" className="show-scroll align-top" alt="Home"></img>
              <span>Hope for Native America</span>
            </Link>
            <Navbar.Toggle aria-controls="navbarResponsive"/>
            <Navbar.Collapse id="navbarResponsive">
              <Nav className="navbar-nav ml-auto my-2 my-lg-0">
                <li className="nav-item">
                  <Link className="nav-link" to="/get-involved">Get Involved</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/stories">Stories</Link>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="https://donate.h4na.org/giving">Donate</a>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/about">About</Link>
                </li>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </>
    );
  }
}

TopNav.propTypes = {
  useTransparentNav: PropTypes.bool
}